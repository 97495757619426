.add-user {
  .headings {
    display: flex;
    margin-left: 2rem;
    .btn-success {
      margin-left: -45px;
      a {
        color: white;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
}
