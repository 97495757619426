.login-conatiner {
  height: 100vh;
  .login-row {
    height: 100vh;
  }
  body {
    margin: 0;
    width: 100%;
    padding: 0;
    font-family: sans-serif;
    background: #c0c0c0;
    /* height: 100vh; */
    /* linear-gradient(to right, #b92b27, #1565c0); */
  }

  .box {
    width: 500px;
    padding: 40px;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* bottom: 50%;
    right: 50%; */
    background: var(--color-primary) !important;
    text-align: center;
    transition: 0.25s;
    border-radius: 20px;
    /* margin-top: 100px; */
    width: 100%;
  }

  .box input[type="text"],
  .box input[type="password"] {
    border: 0;
    background: none;
    display: block;
    margin: 20px auto;
    text-align: center;
    border: 2px solid #3498db;
    padding: 10px 10px;
    /* width: 250px; */
    outline: none;
    color: #eb2332;
    border-radius: 24px;
    transition: 0.25s;
  }

  .box h1 {
    color: #eb2332;
    text-transform: uppercase;
    font-weight: 500;
  }

  .gapp {
    padding-top: 5px;
  }

  .box input[type="text"]:focus,
  .box input[type="password"]:focus {
    width: 300px;
    border-color: #2ecc71;
  }

  .box input[type="submit"] {
    border: 0;
    background: none;
    display: block;
    margin: 20px auto;
    text-align: center;
    border: 2px solid #2ecc71;
    padding: 14px 40px;
    outline: none;
    color: #eb2332;
    border-radius: 24px;
    transition: 0.25s;
    cursor: pointer;
  }

  .box input[type="submit"]:hover {
    background: #6891ca;
    color: black;
  }

  .forgot {
    text-decoration: underline;
  }

  ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
  }

  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }

  .social-network a.icoFacebook:hover {
    background-color: #3b5998;
  }

  .social-network a.icoTwitter:hover {
    background-color: #33ccff;
  }

  .social-network a.icoGoogle:hover {
    background-color: #bd3518;
  }

  .social-network a.icoFacebook:hover i,
  .social-network a.icoTwitter:hover i,
  .social-network a.icoGoogle:hover i {
    color: #fff;
  }

  a.socialIcon:hover,
  .socialHoverClass {
    color: #44bcdd;
  }

  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    border-radius: 50%;
    text-align: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  .social-circle li i {
    margin: 0;
    line-height: 50px;
    text-align: center;
  }

  .social-circle li a:hover i,
  .triggeredHover {
    transform: rotate(360deg);
    transition: all 0.2s;
  }

  .social-circle i {
    color: #fff;
    transition: all 0.8s;
    transition: all 0.8s;
  }
}
