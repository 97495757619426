.add-user {
  //   padding-left: 50px;
  .headings {
    padding-top: 40px;
    padding-bottom: 30px;
    text-align: center;
  }
  .bt-sub {
    padding-top: 15px;
  }
  .text-align-end {
    text-align: end;
  }
}
