.consent-form {
  .logo {
    width: 400px;
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
  }
  .card-sty {
    width: 100% !important;
  }
  .bt-set {
    flex-direction: column;
    align-items: flex-end;
  }
  .bt-sett {
    display: contents;
    .btc {
      background-color: #d3d3d3;
      color: #333333 !important;
    }
    .consent-form {
      .logo {
        width: 400px;
        display: flex;
        justify-content: center;
      }
      .card-sty {
        width: 100% !important;
      }
      .bt-set {
        margin-top: 5rem;
        flex-direction: column;
        align-items: flex-end;
      }
      .bt-sett {
        display: contents;
        .btc {
          background-color: #d3d3d3;
          color: #333333 !important;
        }
      }
      .gappp {
        padding-top: 40px;
        padding-bottom: 20px;
      }
      .card-gap {
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .card {
          width: 50%;
        }
        .reject-bt {
          // padding-left: 5px;
          margin-left: 10px;
        }
      }
      .require {
        display: flex;
      }
      .alignn {
        text-align: initial !important;
      }
      .add-fam {
        width: 100% !important;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;
        margin-top: 1rem;
        background-color: #618bc8;
        color: white;
      }
      .warn {
        text-align: center;
        padding-top: 8px;
      }
    }
  }
  .gappp {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .card-gap {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .card {
      width: 50%;
    }
    .reject-bt {
      // padding-left: 5px;
      margin-left: 10px;
    }
  }
  .require {
    display: flex;
  }
  .add-fam {
    width: 100% !important;
    // padding-left: 2rem;
    // padding-right: 2rem;
    // padding-top: 1rem;
    margin-top: 1rem;
    // display: flex;
    background-color: #618bc8;
    color: white;
    // justify-content: center;
  }
  .safari {
    flex: auto;
  }
  .warn {
    text-align: center;
    padding-top: 8px;
  }
}
