.add-answer {
  .headings {
    display: flex;
    margin: 2rem;
    .btn-success{
      margin-left: -45px;
      a{
        color:white;
      }
      a:hover{
        text-decoration: none;;
      }
    }
  }
  
}
