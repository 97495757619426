.add-ans{
  margin-right: 1rem;
  margin-left: 1rem;
  // margin-top: 2rem;
}
.bt-set{
  margin-top: 2rem;
  flex-direction: column;
  align-items: flex-end;
}
.heading {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 480px) {
  .add-ans {
    margin-left: 5rem;
    margin-right: 5rem;
    // margin-top: 2rem;
    .heading {
      display: flex;
      justify-content: center;
    }
    .bt-set {
      padding-top: 4rem;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 2rem !important;
    }
    .bt-sett {
      display: contents;
      .btc {
        background-color: #618bc8;
      }
    }
    .f-size{
      font-weight: 600;
    }
    .numInput {
      width: 92%;
      margin-left: 1rem;
    }
    .q-form {
      // padding-left: 6rem;
      // padding-right: 6rem;
      padding-top: 2rem;
    }
  }
}

