.add-question {
  //   color: blue;
  .heading {
    padding-top: 40px;
    padding-bottom: 30px;
    text-align: center !important;
  }

  .borderr {
    border: solid 1px;
  }
  .fSize {
    font-size: larger !important;
  }
}
.container {
  max-width: 960px !important;
  text-align: center;
}
