.greeting {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 13rem;
  .datee {
    display: block;
    text-align: center;
  }
}
